exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-candidate-policy-index-tsx": () => import("./../../../src/pages/candidate-policy/index.tsx" /* webpackChunkName: "component---src-pages-candidate-policy-index-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */)
}

